import { useEffect } from "react";
import { auth, firestore } from "./config";
import { useNavigate } from "react-router-dom";
import { collection, where, query, getDocs } from "firebase/firestore";

const AuthHandler = () => {
  const navigate = useNavigate();

  // Function to check if the business is registered
  const registerBusiness = async (uid) => {
    try {
      const q = query(
        collection(firestore, "business"),
        where("users_id", "==", uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Check if business is registered
        const userDocument = querySnapshot.docs[0];
        const data = userDocument.data();

        if (!data.is_registered) {
          // Redirect to register business
          navigate("/register-business");
        }
      }
    } catch (error) {
      console.log("Cannot get user data", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        registerBusiness(user.uid);
        console.log("User logged in");
      } else {
        navigate("/login");
        console.log("User logged out");
      }
    });

    // Cleanup Firebase Auth listener on unmount
    return () => unsubscribe();
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default AuthHandler;

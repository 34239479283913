import "../styles/Login.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "@/firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

import Loading from "@/components/Loader";
import { Input } from "@Components/input";
import { Button } from "@Components/button";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const signIn = async () => {
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      // Navigate to home page
      const user = userCredential.user;
      navigate("/");
    } catch (error) {
      console.error("Error code:", error.code, "Error message:", error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="loginWrapper">
      <div className="loginLeft">
        <div className="w-[70%]">
          <img
            alt="iSettle"
            src={require("../assets/logo.png")}
            className="logo"
          />

          <h3>Welcome to iSettle👋</h3>
          <p>Enter your email and password to login</p>

          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
            placeholder="Enter email"
          />

          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            placeholder="Enter password"
          />

          <Button variant="outline" onClick={signIn} className="login-button">
            Login
          </Button>
        </div>
      </div>

      <div className="loginRight">
        <div className="contentBox">
          <h3>Making mediation simple and easy.</h3>
          <p>
            iSettle does what you cannot do. Cut down on the hassle of running
            around and wasting precious time and money on small mediation cases!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;

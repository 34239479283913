const formatDate = (startDate) => {
  let date;

  if (startDate instanceof Date) {
    // If it's already a Date object
    date = startDate;
  } else if (typeof startDate === "string") {
    // Handle ISO 8601 date string format
    date = new Date(startDate);
  } else if (startDate?.seconds) {
    // Handle Firestore Timestamp format
    date = new Date(startDate.seconds * 1000);
  } else if (startDate?._seconds) {
    // Handle other formats (like custom objects with _seconds)
    date = new Date(startDate._seconds * 1000);
  } else {
    // Fallback in case no valid date is found
    console.warn("Invalid date format:", startDate);
    return null;
  }

  // Format the date in DD/MM/YYYY format
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export default formatDate;

import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import { Card, CardContent } from "@/components/ui/card"; // Adjust path as needed
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"; // Adjust path as needed

import { getTrendChartMetrics } from "@/scripts/chartMetrics";
export const description = "A line chart";

// No need for `satisfies ChartConfig` in JavaScript
const chartConfig = {
  desktop: {
    label: "Resolved",
    color: "hsl(var(--chart-1))",
  },
};

export default function Component({ data }) {
  const chartData = getTrendChartMetrics(data);

  return (
    <Card>
      <CardContent className="p-5 rounded-lg">
        <ChartContainer config={chartConfig}>
          <LineChart
            data={chartData}
            margin={{
              left: 12,
              right: 12,
              top: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Line
              dataKey="desktop"
              type="natural"
              stroke="var(--color-desktop)"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

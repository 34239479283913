import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Loading from "./Loader";
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup Firebase Auth listener on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; // Optional loading state
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  //   return children; // Render the protected route content if authenticated

  return (
    <div className="flex h-screen">
      <Sidebar />
      <Navbar />

      <div
        style={{
          width: "80%",
          marginLeft: "20%",
          paddingTop: "72px",
        }}
      >
        {children} {/* Render the protected route content */}
      </div>
    </div>
  );
};

export default ProtectedRoute;

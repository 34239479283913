import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { MoreHorizontal } from "lucide-react";
import Loader from "@/components/Loader";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import fetchAllClients from "@/scripts/fetchAllClients";

function ClientList() {
  const navigate = useNavigate();
  const [clients, setClients] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();

    if (query === "") {
      setFilteredClients(clients);
    } else {
      const filteredPeople = clients.filter((person) => {
        const name = person.name ? person.name.toLowerCase() : "";
        const phone = person.phone ? person.phone.toString().toLowerCase() : "";
        const email = person.email ? person.email.toLowerCase() : "";

        return (
          name.includes(query) || phone.includes(query) || email.includes(query)
        );
      });

      setFilteredClients(filteredPeople);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const clientList = await fetchAllClients();
      setClients(clientList);
      setFilteredClients(clientList);
      setLoading(false);
    };

    getData();
  }, []);

  if (!clients || loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <h2 className="text-md text-gray-600 font-bold">Clients List</h2>

      <Card className="p-4 mt-3">
        <h2 className="text-sm text-gray-600 font-semibold mb-2">
          Search client
        </h2>
        <Input
          type="text"
          placeholder="Type name, phone, email"
          onChange={handleSearch}
        />
      </Card>

      <Card className="p-4 mt-3">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Name</TableHead>
              <TableHead className="font-bold">Phone</TableHead>
              <TableHead className="font-bold">Email</TableHead>
              <TableHead className="font-bold">Verified</TableHead>
              <TableHead className="font-bold">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredClients.map((client, i) => (
              <TableRow key={i}>
                <TableCell className="font-medium">{i + 1}</TableCell>
                <TableCell>{client?.name}</TableCell>
                <TableCell className="text-[16px]">
                  {client?.phone && `+91 ${client?.phone}`}
                </TableCell>
                <TableCell>{client?.email}</TableCell>
                <TableCell>{client?.is_verified ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <Popover>
                    <PopoverTrigger className="bg-blue-500 p-2 px-4 text-white rounded-[20px]">
                      <MoreHorizontal />
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col">
                      <Button
                        className="m-1"
                        onClick={() =>
                          navigate(`/client-progress/${client.users_id}`)
                        }
                      >
                        Cases
                      </Button>
                      <Button
                        className="m-1"
                        onClick={() =>
                          navigate(`/client-payment/${client.users_id}`)
                        }
                      >
                        Payments
                      </Button>
                    </PopoverContent>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default ClientList;

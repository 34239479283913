import { firestore } from "../firebase/config";
import { query, collection, getDocs } from "firebase/firestore";

const getAllNeutrals = async () => {
  try {
    // Create a query to fetch the 'cases' collection
    const casesQuery = query(collection(firestore, "neutrals"));

    // Execute the query and get the documents
    const querySnapshot = await getDocs(casesQuery);

    // If documents exist, map through them and return the list
    if (!querySnapshot.empty) {
      const list = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      console.log(`Fetched ${list.length} neutrals`); // Log success message
      return list;
    } else {
      console.log("No neutrals found"); // Log when no data is found
      return [];
    }
  } catch (error) {
    // Improved error handling with detailed error message
    console.error("Error fetching neutrals list: ", error.message);
    return [];
  }
};

export default getAllNeutrals;

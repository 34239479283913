const getRegistrationDate = (startDate) => {
  if (startDate instanceof Date) {
    // If it's already a Date object, return it
    return startDate;
  } else if (typeof startDate === "string") {
    // Handle ISO 8601 date string format
    return new Date(startDate);
  } else if (startDate?.seconds) {
    // Handle Firestore Timestamp format
    return new Date(startDate.seconds * 1000);
  } else if (startDate?._seconds) {
    // Handle other formats (like custom objects with _seconds)
    return new Date(startDate._seconds * 1000);
  } else {
    // Fallback in case no valid date is found
    console.warn("Invalid date format:", startDate);
    return null;
  }
};

function getBarChartMetrics(data) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Initialize an object to store counts of registered and resolved cases by month
  const monthCounts = months.reduce((acc, month) => {
    acc[month] = { registered: 0, resolved: 0 };
    return acc;
  }, {});

  data.forEach((caseItem) => {
    // Get the registration date (start_date) and convert it to a JS Date object
    const registrationDate = getRegistrationDate(caseItem.start_date);
    const monthName = months[registrationDate.getMonth()];

    // Increment the registered count for the corresponding month
    monthCounts[monthName].registered += 1;

    // Check if the case is resolved (based on `is_successful` or `is_resolved`)
    if (caseItem.is_successful) {
      monthCounts[monthName].resolved += 1;
    }
  });

  // Format the data to match the required chartData format
  const chartData = months.map((month) => ({
    month,
    registered: monthCounts[month].registered, // Registered count
    resolved: monthCounts[month].resolved, // Resolved count
  }));

  return chartData;
}

function getPieChartMetrics(data) {
  const disputeCategories = {
    "Account Aggregator": "#ff6f61",
    "Commercial Disputes": "#4caf50",
    "Consumer Disputes": "#2196f3",
    "E-Commerce Disputes": "#ff9800",
    "Employment Disputes": "#9c27b0",
    "Family Disputes": "#f44336",
    "Financial Disputes": "#00bcd4",
    "Government and PSU Disputes": "#8bc34a",
    "Housing-Rental": "#ff5722",
    ONDC: "#673ab7",
    "Other Civil Disputes": "#795548",
    "Property Disputes": "#3f51b5",
    "Securities Markets": "#607d8b",
    Other: "#9e9e9e",
  };

  // Ensure data is an array
  if (!data || !Array.isArray(data)) {
    return [];
  }

  // Normalize category names (e.g., handle singular/plural differences)
  const normalizedCategories = {
    "Property Dispute": "Property Disputes",
    "Family Dispute": "Family Disputes",
    "Commercial Dispute": "Commercial Disputes",
    // Add other normalizations if necessary
  };

  // Count occurrences of each category
  const categoryCounts = data.reduce((acc, caseItem) => {
    let category = caseItem.category;

    // Normalize the category name
    category = normalizedCategories[category] || category;

    if (category) {
      acc[category] = (acc[category] || 0) + 1;
    }
    return acc;
  }, {});

  // Sort categories by occurrence
  const sortedCategories = Object.entries(categoryCounts).sort(
    (a, b) => b[1] - a[1]
  );

  // Take the top 4 categories, group the rest as "Other"
  let topCategories = sortedCategories.slice(0, 4);
  const otherCount = sortedCategories
    .slice(4)
    .reduce((acc, [_, count]) => acc + count, 0);

  if (otherCount > 0) {
    topCategories.push(["Other", otherCount]);
  }

  // Map the top categories into the desired format
  const chartData = topCategories.map(([category, count]) => ({
    browser: category,
    visitors: count,
    fill: disputeCategories[category] || disputeCategories["Other"],
  }));

  return chartData;
}

function getTrendChartMetrics(data) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Initialize an object to store counts of registered and resolved cases by month
  const monthCounts = months.reduce((acc, month) => {
    acc[month] = { registered: 0, resolved: 0 };
    return acc;
  }, {});

  data.forEach((caseItem) => {
    // Get the registration date (start_date) and convert it to a JS Date object
    const registrationDate = getRegistrationDate(caseItem.start_date);

    const monthName = months[registrationDate.getMonth()];

    // Increment the registered count for the corresponding month
    monthCounts[monthName].registered += 1;

    // Check if the case is resolved (based on `is_successful` or `is_resolved`)
    if (caseItem.is_successful) {
      monthCounts[monthName].resolved += 1;
    }
  });

  // Format the data to match the required chartData format
  const chartData = months.map((month) => ({
    month,
    desktop: monthCounts[month].resolved, // Resolved count
  }));

  return chartData;
}

export { getBarChartMetrics, getPieChartMetrics, getTrendChartMetrics };

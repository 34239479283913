import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card"; // Adjust the path to your project structure
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"; // Adjust the path to your project structure

import { getBarChartMetrics } from "@/scripts/chartMetrics";
export const description = "A multiple bar chart";

const chartConfig = {
  registered: {
    label: "Registered",
    color: "hsl(var(--chart-1))",
  },
  resolved: {
    label: "Resolved",
    color: "hsl(var(--chart-2))",
  },
};

export default function Component({ data }) {
  const chartData = getBarChartMetrics(data);

  return (
    <Card className="w-[66%] mt-3">
      <CardHeader>
        <CardDescription>January - December 2024</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Bar
              dataKey="registered"
              fill="var(--color-registered)"
              radius={4}
            />
            <Bar dataKey="resolved" fill="var(--color-resolved)" radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

import React from "react";
import { auth } from "../firebase/config";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  const logout = async () => await auth.signOut();

  return (
    <div className="h-screen fixed top-0 left-0 w-[20%] bg-white p-6 z-10 shadow-md">
      <img
        style={{
          width: "110px",
          height: "25px",
          objectFit: "contain",
          margin: "10px auto 40px",
        }}
        alt="iSettle"
        src={require("../assets/sidebar-logo.png")}
      />

      <div
        className="flex items-center mb-5 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <svg
          className="w-6 h-6 ml-2 text-gray-400 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-3 text-gray-400 text-lg">Home</span>
      </div>

      <div
        className="flex items-center mb-5 cursor-pointer"
        onClick={() => navigate("/dispute-registry")}
      >
        <img
          src={require("../assets/justice-hammer.png")}
          className="w-6 h-6 ml-2 text-gray-400 dark:text-white"
          alt="Disputes Icon"
        />
        <span className="ml-3 text-gray-400 text-lg">Disputes</span>
      </div>

      <div
        className="flex items-center mb-5 cursor-pointer"
        onClick={() => navigate("/clients")}
      >
        <svg
          className="w-7 h-7 ml-1 text-gray-400 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-3 text-gray-400 text-lg">Clients</span>
      </div>

      <div
        className="flex items-center mb-5 cursor-pointer"
        onClick={() => navigate("/neutrals")}
      >
        <svg
          className="w-7 h-7 ml-1 text-gray-400 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-3 text-gray-400 text-lg">Neutrals</span>
      </div>

      <div onClick={logout} className="flex items-center cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 ml-2 mr-1 text-gray-400 dark:text-white"
        >
          <path
            d="M3 1C2.44771 1 2 1.44772 2 2V13C2 13.5523 2.44772 14 3 14H10.5C10.7761 14 11 13.7761 11 13.5C11 13.2239 10.7761 13 10.5 13H3V2L10.5 2C10.7761 2 11 1.77614 11 1.5C11 1.22386 10.7761 1 10.5 1H3ZM12.6036 4.89645C12.4083 4.70118 12.0917 4.70118 11.8964 4.89645C11.7012 5.09171 11.7012 5.40829 11.8964 5.60355L13.2929 7H6.5C6.22386 7 6 7.22386 6 7.5C6 7.77614 6.22386 8 6.5 8H13.2929L11.8964 9.39645C11.7012 9.59171 11.7012 9.90829 11.8964 10.1036C12.0917 10.2988 12.4083 10.2988 12.6036 10.1036L14.8536 7.85355C15.0488 7.65829 15.0488 7.34171 14.8536 7.14645L12.6036 4.89645Z"
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span className="ml-3 text-gray-400 text-lg">Logout</span>
      </div>
    </div>
  );
}

export default Sidebar;

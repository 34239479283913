import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import Loader from "@/components/Loader";
import { CheckIcon } from "lucide-react";
import { ArrowLeft } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@radix-ui/react-label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import getAllNeutrals from "@/scripts/fetchAllNeutrals";
import getAllCounsellors from "@/scripts/fetchAllCounsellors";

function CaseDetails() {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [caseDetails, setCaseDetails] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const [neutral, setNeutral] = useState();
  const [counsellor, setCounsellor] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState();
  const [counsellingLink, setCounsellingLink] = useState();
  const [hearingLink, setHearingLink] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [isSuccessful, setIsSuccessful] = useState();

  // Case Fee
  const [caseFeeA, setCaseFeeA] = useState();
  const [caseFeeB, setCaseFeeB] = useState();
  // Fetched List
  const [neutralList, setNeutralList] = useState();
  const [counsellorList, setCounsellorList] = useState();

  function convertFirestoreTimestamp(seconds, nanoseconds) {
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  const statusMapping = {
    "Case Registered": 1,
    "Counselling Call": 2,
    "Dispute Hearing": 3,
    Settlement: 4,
    "Case Closed": 5,
  };

  const options = [
    { label: "Case Registered", value: 1 },
    { label: "Counselling Call", value: 2 },
    { label: "Dispute Hearing", value: 3 },
    { label: "Settlement", value: 4 },
    { label: "Case Closed", value: 5 },
  ];

  const categories = [
    "Account Aggregator",
    "Commercial Disputes",
    "Consumer Disputes",
    "E-Commerce Disputes",
    "Employment Disputes",
    "Family Disputes",
    "Financial Disputes",
    "Government and PSU Disputes",
    "Housing-Rental",
    "ONDC",
    "Other Civil Disputes",
    "Property Disputes",
    "Securities Markets",
  ];

  const updateCase = async () => {
    try {
      setLoading(true);
      const caseId = caseDetails.case_id;
      const caseRef = doc(firestore, "cases", caseId);

      const updatedDetails = {
        category: category || "",
        description: description || "",
        hearing_link: hearingLink || "",
        current_status: currentStatus || "",
        is_successful: isSuccessful || false,
        counselling_link: counsellingLink || "",
        // Neutral Select
        neutral_preference: neutral.users_id || "",
        neutral: {
          name: neutral.name || "",
          phone: neutral.phone || "",
        },
        // Counsellor Select
        counsellor_id: counsellor.users_id || "",
        counsellor: {
          name: counsellor.name || "",
          phone: counsellor.phone || "",
        },
      };

      // Update doc (fields will be merged)
      await updateDoc(caseRef, updatedDetails);
      setLoading(false);
      setRefresh(!refresh);
      console.log("Case details updated successfully");
    } catch (error) {
      console.error("Error updating case details: ", error);
    }
  };

  const updatePayment = async () => {
    try {
      setLoading(true);
      const updatedDetails = {};
      const caseId = caseDetails.case_id;
      const caseRef = doc(firestore, "cases", caseId);

      if (caseFeeA) {
        updatedDetails[
          "party_involved.A.payment.case_settlement_payment.amount"
        ] = caseFeeA;
      }
      if (caseFeeB) {
        updatedDetails[
          "party_involved.B.payment.case_settlement_payment.amount"
        ] = caseFeeB;
      }

      // Update doc (fields will be merged)
      if (Object.keys(updatedDetails).length > 0) {
        await updateDoc(caseRef, updatedDetails);
        console.log("Case details updated successfully");
      } else {
        console.log("No fields to update.");
      }

      setRefresh(!refresh);
    } catch (error) {
      console.error("Error updating case details: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCaseDetails = async () => {
    const caseDoc = doc(firestore, "cases", caseId);
    const caseSnapshot = await getDoc(caseDoc);

    if (caseSnapshot.exists()) {
      const details = caseSnapshot.data();

      setCaseDetails(details);
      setCurrentStep(statusMapping[details.current_status] || 1);

      // Update fields
      setCategory(details?.category);
      setDescription(details?.description);
      setHearingLink(details?.hearing_link);
      setCounsellingLink(details?.counselling_link);
      setCounsellor(details?.counsellor_id);
      setCurrentStatus(details?.current_status);
      setIsSuccessful(details?.is_successful);
      // Set Neutral
      setNeutral({
        users_id: details?.neutral_preference || "",
        name: details?.neutral?.name || "",
        phone: details?.neutral?.phone || "",
      });
      // Set Counsellor
      setCounsellor({
        users_id: details?.counsellor_id || "",
        name: details?.counsellor?.name || "",
        phone: details?.counsellor?.phone || "",
      });
      // Set Payments
      setCaseFeeA(
        details?.party_involved?.A?.payment?.case_settlement_payment?.amount
      );
      setCaseFeeB(
        details?.party_involved?.B?.payment?.case_settlement_payment?.amount
      );
    } else {
      alert("No such case found!");
      navigate("/dispute-registry");
    }
  };

  const fetchList = async () => {
    const neutralData = await getAllNeutrals();
    const counsellorData = await getAllCounsellors();
    setNeutralList(neutralData);
    setCounsellorList(counsellorData);
  };

  useEffect(() => {
    fetchList();
    fetchCaseDetails();
  }, [caseId, refresh, navigate]);

  if (!caseDetails || loading || !neutralList || !counsellorList) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div className="flex items-center justify-between">
        <button
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft color="grey" />
          <p className="ml-2 text-gray-500">Back</p>
        </button>
        <p className="text-md text-grey-500">
          Registered on &nbsp;
          {convertFirestoreTimestamp(
            caseDetails.start_date.seconds,
            caseDetails.start_date.nanoseconds
          ).toLocaleString()}
        </p>
      </div>

      <div className="flex justify-between">
        {/* Title */}
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-2xl font-bold text-grey-500">
            {caseDetails?.category ? caseDetails?.category : "Dispute"}
          </h3>
          <h3 className="text-lg font-semibold text-blue-500 mt-1">
            {`${caseDetails?.party_involved?.A?.party_name}, ${caseDetails?.party_involved?.B?.party_name}`}
          </h3>
          <h3 className="text-md font-semibold">ID: {caseDetails.case_id}</h3>
        </Card>

        {/* Case Status */}
        <Card className="w-[66%]  mt-3 p-4 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 1 ? <CheckIcon /> : 1}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Registered
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 2 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 2
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 2 ? <CheckIcon /> : 2}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Counselling Call
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 3 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 3
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 3 ? <CheckIcon /> : 3}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Dispute Hearing
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 4 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 4
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 4 ? <CheckIcon /> : 4}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Settlement
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 5 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 5
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              5
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Case Closed
            </span>
          </div>
        </Card>
      </div>

      {/* Update Details */}
      <Card className="w-100 mt-3 p-4">
        <h3 className="text-md font-bold mb-2">Update Case</h3>
        <Label htmlFor="currentStatus">Current Stage</Label>
        <span className="text-sm ml-2 text-red-500">
          {currentStatus !== caseDetails?.current_status && "(Value Changed)"}
        </span>
        <Select
          value={currentStatus}
          onValueChange={(selected) => setCurrentStatus(selected)} // update state with selected value
        >
          <SelectTrigger id="currentStatus" className="mb-3 mt-1">
            <SelectValue placeholder="Select Current Stage" />
          </SelectTrigger>
          <SelectContent>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.label}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <h3 className="text-md font-bold mb-2">Registered (1)</h3>
        <Label htmlFor="category">Category</Label>
        <span className="text-sm ml-2 text-red-500">
          {category !== caseDetails?.category && "(Value Changed)"}
        </span>
        <Select
          value={category}
          onValueChange={(selected) => setCategory(selected)} // update state with selected value
        >
          <SelectTrigger
            id="category"
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          >
            <SelectValue placeholder="Select category" />
          </SelectTrigger>
          <SelectContent>
            {categories.map((category) => (
              <SelectItem key={category} value={category}>
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Label htmlFor="description">Description</Label>
        <span className="text-sm ml-2 text-red-500">
          {description !== caseDetails?.description && "(Value Changed)"}
        </span>
        <Textarea
          type="text"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
          className="mb-3 mt-1"
          rows={5}
        />
        <h3 className="text-md font-bold mb-2">Counselling (2)</h3>
        <Label htmlFor="neutral">Case Neutral</Label>
        <span className="ml-2 text-red-500">
          {caseDetails?.neutral?.name} ({caseDetails?.neutral?.phone})
        </span>

        <Select
          value={neutral}
          onValueChange={(selected) => setNeutral(selected)}
        >
          <SelectTrigger
            id="neutral"
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          >
            <SelectValue placeholder="Select neutral" />
          </SelectTrigger>
          <SelectContent>
            {neutralList.map((item) => (
              <SelectItem key={item.users_id} value={item}>
                {item.name} ({item.phone})
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Label htmlFor="neutral">Case Counsellor</Label>
        <span className="ml-2 text-red-500">
          {caseDetails?.counsellor?.name} ({caseDetails?.counsellor?.phone})
        </span>

        <Select
          value={counsellor}
          onValueChange={(selected) => setCounsellor(selected)}
        >
          <SelectTrigger
            id="counsellor"
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          >
            <SelectValue placeholder="Select counsellor" />
          </SelectTrigger>
          <SelectContent>
            {counsellorList.map((item) => (
              <SelectItem key={item.users_id} value={item}>
                {item.name} ({item.phone})
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Label htmlFor="counsellingLink">Counselling Link</Label>
        <span className="text-sm ml-2 text-red-500">
          {counsellingLink !== caseDetails?.counselling_link &&
            "(Value Changed)"}
        </span>
        <Input
          type="text"
          id="counsellingLink"
          value={counsellingLink}
          onChange={(e) => setCounsellingLink(e.target.value)}
          placeholder="Counselling Link"
          className="mb-3 mt-1"
        />

        <h3 className="text-md font-bold mb-2">Dispute Hearing (3)</h3>
        <Label htmlFor="hearingLink">Hearing Link</Label>
        <span className="text-sm ml-2 text-red-500">
          {hearingLink !== caseDetails?.hearing_link && "(Value Changed)"}
        </span>
        <Input
          type="text"
          id="hearingLink"
          value={hearingLink}
          onChange={(e) => setHearingLink(e.target.value)}
          placeholder="Meeting Link"
          className="mb-3 mt-1"
        />
        <h3 className="text-md font-bold mb-2">Settlement (4)</h3>
        <h3 className="text-md font-bold mb-2">Case Closed (5)</h3>
        <Label htmlFor="isSuccessful">Case Successful</Label>
        <span className="text-sm ml-2 text-red-500">
          {isSuccessful !== caseDetails?.is_successful && "(Value Changed)"}
        </span>
        <Select
          value={isSuccessful}
          onValueChange={(selected) => setIsSuccessful(selected)}
        >
          <SelectTrigger
            id="isSuccessful"
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            className="mt-1"
          >
            <SelectValue placeholder="Select Case Success" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={true}>True</SelectItem>
            <SelectItem value={false}>False</SelectItem>
          </SelectContent>
        </Select>
        <Button onClick={updateCase} className="w-[100%] mt-6">
          Update
        </Button>
      </Card>

      {/* Payments */}
      <Card className="w-100 mt-3 p-4">
        <h3 className="font-bold text-grey-500">Case Payments</h3>

        <h3 className="text-md font-bold text-grey-500 mt-2">
          Party A: {caseDetails?.party_involved?.A?.party_name}
        </h3>
        <Input
          type="text"
          value={caseFeeA}
          onChange={(e) => setCaseFeeA(e.target.value)}
          placeholder="Payment amount"
          className="mb-3 mt-2"
        />

        <h3 className="text-md font-bold text-grey-500 mt-3">
          Party B: {caseDetails?.party_involved?.B?.party_name}
        </h3>
        <Input
          type="text"
          value={caseFeeB}
          onChange={(e) => setCaseFeeB(e.target.value)}
          placeholder="Payment amount"
          className="mb-3 mt-2"
        />

        <Button onClick={updatePayment} className="w-[100%] mt-6">
          Update
        </Button>
      </Card>
    </div>
  );
}

export default CaseDetails;

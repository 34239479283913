import { firestore } from "../firebase/config";
import { query, collection, where, getDocs } from "firebase/firestore";

const fetchCasesByNeutralId = async (uid) => {
  try {
    // Query to find cases where preferred_neutral is equal to uid
    const neutralQuery = query(
      collection(firestore, "cases"),
      where("preferred_neutral", "==", uid)
    );

    // Fetch the documents from the cases collection
    const querySnapshot = await getDocs(neutralQuery);

    // Map through the documents and return the list of cases
    const cases = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (cases.length > 0) {
      console.log(`Fetched ${cases.length} cases`);
    }

    return cases;
  } catch (error) {
    console.error("Error fetching cases: ", error);
    return [];
  }
};

export default fetchCasesByNeutralId;

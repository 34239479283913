import "../styles/Login.scss";
import React from "react";
import { Button } from "@Components/button";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div
      className="loginWrapper"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        alt="iSettle"
        className="logo"
        src={require("../assets/logo.png")}
        style={{ height: 30, objectFit: "contain" }}
      />

      <h3 className="mt-6 text-xl font-bold">Page Not Found</h3>
      <p className="my-2 text-lg">The page you're looking for doesn't exist</p>

      <Button
        variant="outline"
        style={{ width: 200 }}
        className="login-button"
        onClick={() => navigate("/")}
      >
        Go to Home
      </Button>
    </div>
  );
}

export default NotFound;

import { firestore } from "../firebase/config";
import { query, collection, where, getDocs } from "firebase/firestore";

const fetchPaymentsByPayerId = async (uid) => {
  try {
    // Query to find payments where payer_id is equal to uid
    const paymentsQuery = query(
      collection(firestore, "payments"),
      where("payer_id", "==", uid)
    );

    // Fetch the documents from the payments collection
    const querySnapshot = await getDocs(paymentsQuery);

    // Map through the documents and return the list of payments
    const payments = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (payments.length > 0) {
      console.log(`Fetched ${payments.length} payments`);
    } else {
      console.log("No payments found");
    }

    return payments;
  } catch (error) {
    console.error("Error fetching payments: ", error);
    return [];
  }
};

export default fetchPaymentsByPayerId;

import React from "react";
import { useNavigate } from "react-router-dom";
import formatDate from "@/scripts/formatDate";

const DisputeCard = ({ data }) => {
  const navigate = useNavigate();
  const date = formatDate(data.start_date);
  const status =
    data.current_status === "Case Registered" ? "Processing" : "In Progress";

  return (
    <div
      onClick={() => navigate(`/case-details/${data?.case_id}`)}
      className="w-[32%] flex flex-row border rounded-lg shadow-lg overflow-hidden mb-4 cursor-pointer"
    >
      {/* Case Info */}
      <div className="flex flex-col justify-center p-4 w-3/4">
        <p className="text-gray-500 text-sm mb-1">{date}</p>
        <h3 className="text-xl font-semibold mb-1">
          {data.category === "Select" ? "Undefined Dispute" : data.category}
        </h3>
        <p className="text-sm text-gray-500 mb-4">
          {`${data?.party_involved?.B?.party_name}, ${data?.party_involved?.A?.party_name}`}
        </p>

        <div
          className={`flex items-center space-x-2 px-2 py-1 rounded-full ${
            status === "Processing" ? "bg-orange-100" : "bg-blue-100"
          }`}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Radio button */}
            <path
              d="M7.49985 0.877045C3.84216 0.877045 0.877014 3.84219 0.877014 7.49988C0.877014 11.1575 3.84216 14.1227 7.49985 14.1227C11.1575 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1575 0.877045 7.49985 0.877045ZM1.82701 7.49988C1.82701 4.36686 4.36683 1.82704 7.49985 1.82704C10.6328 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6328 13.1727 7.49985 13.1727C4.36683 13.1727 1.82701 10.6329 1.82701 7.49988ZM7.49999 9.49999C8.60456 9.49999 9.49999 8.60456 9.49999 7.49999C9.49999 6.39542 8.60456 5.49999 7.49999 5.49999C6.39542 5.49999 5.49999 6.39542 5.49999 7.49999C5.49999 8.60456 6.39542 9.49999 7.49999 9.49999Z"
              fill={status === "Processing" ? "#FFA500" : "#3B6DFF"}
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
          <p
            className={`${
              status === "Processing" ? "text-orange-500" : "text-blue-600"
            } text-sm`}
          >
            {status}
          </p>
        </div>
      </div>

      {/* Right design */}
      <div className="w-1/4 flex items-center justify-end">
        <svg
          width="90"
          height="100%"
          viewBox="0 0 90 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.2813 238.741L167.286 238.741C183.726 238.741 197.054 225.413 197.054 208.972L197.054 30.2187C197.054 13.7779 183.727 0.449943 167.286 0.449944L13.4134 0.44995C6.61252 0.449951 2.95843 8.44141 7.40723 13.5853C9.98682 16.5679 9.98682 20.9917 7.40726 23.9743L3.97331 27.9449C-0.577972 33.2073 -0.577957 41.0124 3.97332 46.2749C8.52462 51.5374 8.52463 59.3425 3.97334 64.6049C-0.577957 69.8674 -0.577962 77.6726 3.97333 82.935C8.52463 88.1975 8.52463 96.0026 3.97334 101.265C-0.577961 106.528 -0.57796 114.333 3.97334 119.595C8.52463 124.858 8.52463 132.663 3.97334 137.925C-0.577958 143.188 -0.577958 150.993 3.97334 156.255C8.52464 161.518 8.52463 169.323 3.97334 174.585C-0.57795 179.848 -0.577954 187.653 3.97334 192.915C8.52463 198.178 8.52463 205.983 3.97334 211.246C-0.577953 216.508 -0.57796 224.313 3.97333 229.576L6.41708 232.401C9.89887 236.427 14.9586 238.741 20.2813 238.741Z"
            fill={status === "Processing" ? "#FFA500" : "#3B6DFF"}
          />
        </svg>
      </div>
    </div>
  );
};

export default DisputeCard;

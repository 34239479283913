import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ArrowLeft } from "lucide-react";
import Loader from "@/components/Loader";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";

import formatDate from "@/scripts/formatDate";
import fetchCasesByPartyId from "@/scripts/fetchCasesByPartyId";

function ClientCases() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [disputes, setDisputes] = useState();

  const payments = (id) => navigate(`/edit-case/${id}`);
  const progress = (id) => navigate(`/case-details/${id}`);

  useEffect(() => {
    const getData = async () => {
      const disputeList = await fetchCasesByPartyId(clientId);
      setDisputes(disputeList);
    };

    getData();
  }, []);

  if (!disputes) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div
        className="flex items-center mb-2 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft color="grey" />
        <p className="ml-1 text-gray-500">Back</p>
      </div>

      <h2 className="text-md text-gray-600 font-bold">Client Cases</h2>

      <Card className="p-4 mt-3">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Claimant</TableHead>
              <TableHead className="font-bold">Respondent</TableHead>
              <TableHead className="font-bold">Category</TableHead>
              <TableHead className="font-bold">Status</TableHead>
              <TableHead className="font-bold">Date</TableHead>
              <TableHead className="font-bold">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {disputes.map((dispute, i) => (
              <TableRow key={i}>
                <TableCell className="font-medium">{i + 1}</TableCell>
                <TableCell>{dispute?.party_involved?.A?.party_name}</TableCell>
                <TableCell>{dispute?.party_involved?.B?.party_name}</TableCell>
                <TableCell>{dispute?.category}</TableCell>
                <TableCell>
                  <div
                    className={`${
                      dispute?.current_status === "Case Registered"
                        ? "border-red-600 text-red-600"
                        : "border-blue-600 text-blue-600"
                    } border rounded-[20px] p-2 px-4 w-[150px] text-center`}
                  >
                    {dispute?.current_status}
                  </div>
                </TableCell>
                <TableCell className="font-medium">
                  {formatDate(dispute?.start_date)}
                </TableCell>
                <TableCell>
                  <Popover>
                    <PopoverTrigger className="bg-blue-500 p-2 px-4 text-white rounded-[20px]">
                      <MoreHorizontal />
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col">
                      <Button
                        className="m-1"
                        onClick={() => progress(dispute?.case_id)}
                      >
                        View
                      </Button>
                      <Button
                        className="m-1"
                        onClick={() => payments(dispute?.case_id)}
                      >
                        Update
                      </Button>
                      <Button className="m-1">Delete</Button>
                    </PopoverContent>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default ClientCases;

import { firestore } from "../firebase/config";
import { query, collection, getDocs } from "firebase/firestore";

const fetchAllCounsellors = async () => {
  try {
    const casesQuery = query(collection(firestore, "counsellors"));
    const querySnapshot = await getDocs(casesQuery);

    if (!querySnapshot.empty) {
      const list = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      return list;
    } else {
      console.log("No neutrals found");
      return [];
    }
  } catch (error) {
    console.error("Error fetching neutrals list: ", error.message);
    return [];
  }
};

export default fetchAllCounsellors;

import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const routeTitles = {
  "/": "Admin Panel",
  "/clients": "Clients",
  "/neutrals": "Neutrals",
  "/dispute-registry": "Dispute Registry",
};

const Navbar = () => {
  const location = useLocation();
  const [currentTitle, setCurrentTitle] = useState("");

  useEffect(() => {
    const currentRoute = location.pathname;
    if (currentRoute.startsWith("/case-details")) {
      setCurrentTitle("Case Details");
    } else if (currentRoute.startsWith("/edit-case")) {
      setCurrentTitle("Update Details");
    } else if (currentRoute.startsWith("/client-cases")) {
      setCurrentTitle("Client Cases");
    } else if (currentRoute.startsWith("/client-payment")) {
      setCurrentTitle("Client Payments");
    } else if (currentRoute.startsWith("/neutral-cases")) {
      setCurrentTitle("Neutral Cases");
    } else {
      setCurrentTitle(routeTitles[currentRoute] || "Admin Panel");
    }
  }, [location.pathname]); // Effect runs every time the route changes

  return (
    <nav className="fixed top-0 right-0 h-[72px] w-[80%] bg-white p-4 flex justify-between items-center shadow-md z-10">
      <h1 className="text-lg text-gray-600 font-bold">{currentTitle}</h1>
    </nav>
  );
};

export default Navbar;

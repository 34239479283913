import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";

import Loader from "@/components/Loader";
import { CheckIcon } from "lucide-react";
import { ArrowLeft } from "lucide-react";
import { Card } from "@/components/ui/card";
import { CheckCircle } from "lucide-react";

import formatDate from "@/scripts/formatDate";
import { Button } from "@/components/ui/button";
import fetchEvents from "@/scripts/fetchEvents";

const Title = ({ text }) => {
  return (
    <div className="flex items-center mt-3">
      <CheckCircle color="green" height={20} />
      <h3 className="text-md font-bold ms-2">{text}</h3>
    </div>
  );
};

const CaseTimeline = ({ events }) => {
  return (
    <div>
      {events && events.length > 0 ? (
        <div style={{ position: "relative", marginLeft: "20px" }}>
          <div
            style={{
              position: "absolute",
              width: "4px",
              background: "#007BFF",
              height: "100%",
              left: "10px",
              top: 0,
              borderRadius: "2px",
            }}
          ></div>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {events.map((event, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "20px",
                  position: "relative",
                  paddingLeft: "30px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "4px",
                    top: "0",
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#007BFF",
                    borderRadius: "50%",
                    border: "2px solid #fff",
                    boxShadow: "0 0 0 2px #007BFF",
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "10px 15px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                >
                  <p style={{ margin: "0", fontWeight: "bold" }}>
                    {event.message}
                  </p>
                  <p
                    style={{ margin: "5px 0", fontSize: "12px", color: "#666" }}
                  >
                    {new Date(event.timestamp).toLocaleString()}
                  </p>
                  <p style={{ margin: "0", fontSize: "12px", color: "#333" }}>
                    <strong>Case ID:</strong> {event.caseId}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No events to display.</p>
      )}
    </div>
  );
};

function CaseDetails() {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [caseDetails, setCaseDetails] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const statusMapping = {
    "Case Registered": 1,
    "Counselling Call": 2,
    "Dispute Hearing": 3,
    Settlement: 4,
    "Case Closed": 5,
  };

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    const fetchCaseDetails = async () => {
      const caseDoc = doc(firestore, "cases", caseId);
      const caseSnapshot = await getDoc(caseDoc);

      // Case events
      const events = await fetchEvents(caseId);
      setEvents(events);

      if (caseSnapshot.exists()) {
        const details = caseSnapshot.data();

        setCaseDetails(details);
        setCurrentStep(statusMapping[details.current_status] || 1);
      } else {
        alert("No such case found!");
        navigate("/dispute-registry");
      }
    };

    fetchCaseDetails();
  }, [caseId, navigate]);

  if (!caseDetails || !events) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div className="flex items-center justify-between">
        <button
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft color="grey" />
          <p className="ml-2 text-gray-500">Back</p>
        </button>
        <p className="text-md text-grey-500">
          Registered on &nbsp;
          {formatDate(caseDetails.start_date)}
        </p>
      </div>

      {/* <pre>{JSON.stringify(caseDetails, null, 2)}</pre> */}

      <div className="flex justify-between">
        {/* Title */}
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-2xl font-bold text-grey-500">
            {caseDetails?.category ? caseDetails?.category : "Dispute"}
          </h3>
          <h3 className="text-lg font-semibold text-blue-500 mt-1">
            {`${caseDetails?.party_involved?.A?.party_name}, ${caseDetails?.party_involved?.B?.party_name}`}
          </h3>
          <h3 className="text-md font-semibold">ID: {caseDetails.case_id}</h3>
        </Card>

        {/* Case Status */}
        <Card className="w-[66%]  mt-3 p-4 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 1 ? <CheckIcon /> : 1}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Registered
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 2 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 2
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 2 ? <CheckIcon /> : 2}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Counselling Call
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 3 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 3
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 3 ? <CheckIcon /> : 3}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Dispute Hearing
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 4 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 4
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 4 ? <CheckIcon /> : 4}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Settlement
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 5 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 5
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              5
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Case Closed
            </span>
          </div>
        </Card>
      </div>

      <div className="flex justify-between">
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500">
            Contact Information
          </h3>

          <h3 className="text-sm font-bold text-grey-500 mt-2">Party A</h3>
          <p className="text-sm">
            {caseDetails?.party_involved?.A?.party_name} <br />
            {caseDetails?.party_involved?.A?.party_phone}
          </p>

          <h3 className="text-sm font-bold text-grey-500 mt-2">Party B</h3>
          <p className="text-sm">
            {caseDetails?.party_involved?.B?.party_name} <br />
            {caseDetails?.party_involved?.B?.party_phone}
          </p>

          <h3 className="text-sm font-bold text-grey-500 mt-2">Neutral</h3>
          <p className="text-sm">
            {caseDetails?.neutral?.name} <br />
            {caseDetails?.neutral?.phone}
          </p>

          <h3 className="text-sm font-bold text-grey-500 mt-2">Counsellor</h3>
          <p className="text-sm">
            {caseDetails?.counsellor?.name} <br />
            {caseDetails?.counsellor?.phone}
          </p>
        </Card>

        <Card className="w-[66%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500 mt-2">
            Resolution Method
          </h3>
          <p className="text-md font-semibold text-blue-500 mt-1">
            {caseDetails?.resolution_method
              ? caseDetails?.resolution_method
              : "Not Applicable"}
          </p>

          <h3 className="text-md font-bold text-grey-500 mt-2">Court Status</h3>
          <p className="text-md font-semibold text-blue-500 mt-1">
            {caseDetails?.court_status
              ? caseDetails?.court_status
              : "Not Applicable"}
          </p>

          <h3 className="text-md font-bold text-grey-500">Description</h3>
          <p className="text-ms text-grey-500 mt-1">
            {caseDetails?.description}
          </p>
        </Card>
      </div>

      <div className="flex justify-between">
        {/* Case Card */}
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500">Case Payments</h3>

          <h3 className="text-md font-bold text-grey-500 mt-2">
            Party A: {caseDetails?.party_involved?.A?.party_name}
          </h3>
          <p className="text-ms text-grey-500 mt-1">
            Counselling Fee:{" "}
            {caseDetails?.party_involved?.A?.payment?.case_counselling_payment
              ?.payment_id ? (
              <span className="text-green-500 font-semibold">Paid</span>
            ) : (
              <span className="text-red-500 font-semibold">Due</span>
            )}
          </p>
          <p className="text-ms text-grey-500 mt-1">
            Hearing Fee:{" "}
            {caseDetails?.party_involved?.A?.payment?.case_settlement_payment
              ?.payment_id ? (
              <span className="text-green-500 font-semibold">Paid</span>
            ) : (
              <span className="text-red-500 font-semibold">Due</span>
            )}{" "}
            <span className="text-red-500 font-semibold">
              (
              {
                caseDetails?.party_involved?.A?.payment?.case_settlement_payment
                  ?.amount
              }
              )
            </span>
          </p>

          <h3 className="text-md font-bold text-grey-500 mt-2">
            Party B: {caseDetails?.party_involved?.B?.party_name}
          </h3>
          <p className="text-ms text-grey-500 mt-1">
            Counselling Fee:{" "}
            {caseDetails?.party_involved?.B?.payment?.case_counselling_payment
              ?.payment_id ? (
              <span className="text-green-500 font-semibold">Paid</span>
            ) : (
              <span className="text-red-500 font-semibold">Due</span>
            )}
          </p>
          <p className="text-ms text-grey-500 mt-1">
            Hearing Fee:{" "}
            {caseDetails?.party_involved?.B?.payment?.case_settlement_payment
              ?.payment_id ? (
              <span className="text-green-500 font-semibold">Paid</span>
            ) : (
              <span className="text-red-500 font-semibold">Due</span>
            )}{" "}
            <span className="text-red-500 font-semibold">
              (
              {
                caseDetails?.party_involved?.B?.payment?.case_settlement_payment
                  ?.amount
              }
              )
            </span>
          </p>
        </Card>

        {/* Status Details */}
        <Card className="w-[66%] mt-3 p-4">
          {currentStep >= 1 && <Title text="Case Registered" />}
          {currentStep >= 2 && <Title text="Counselling Call" />}
          {currentStep >= 3 && (
            <div>
              <Title text="Dispute Hearing" />
              <div className="ms-8">
                <p className="text-ms text-grey-500 mt-2">Meeting link:-</p>
                {caseDetails?.hearing_link}
                <div
                  onClick={() => copyToClipboard(caseDetails?.hearing_link)}
                  className="flex items-center justify-center h-[35px] w-[49%] bg-blue-400 rounded-md text-sm text-white mt-2 cursor-pointer font-semibold"
                >
                  Copy
                </div>
              </div>
            </div>
          )}
          {currentStep >= 4 && (
            <div>
              <Title text="Settlement Document" />
              <div className="ms-8 flex">
                <div>
                  <p className="text-ms text-grey-500 mt-2">
                    Party A Signature:-
                  </p>
                  {caseDetails?.party_involved?.A?.signature && (
                    <img
                      alt="signature"
                      src={caseDetails?.party_involved?.A?.signature}
                      className="h-[120px] w-[200px] object-cover border mt-2 mb-4"
                    />
                  )}
                </div>

                <div className="ms-6">
                  <p className="text-ms text-grey-500 mt-2">
                    Party B Signature:-
                  </p>
                  {caseDetails?.party_involved?.B?.signature && (
                    <img
                      alt="signature"
                      src={caseDetails?.party_involved?.B?.signature}
                      className="h-[120px] w-[200px] object-cover border mt-2 mb-4"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {currentStep >= 5 && (
            <div>
              <Title text="Case Closed" />
              <div className="ms-8">
                <p className="text-ms text-grey-500 mt-2">
                  Settlement Document
                </p>
                <div
                  onClick={() => openLink(caseDetails?.document)}
                  className="flex items-center justify-center h-[35px] w-1/2 bg-blue-400 rounded-md text-white mt-3 mb-2 cursor-pointer font-semibold"
                >
                  Download
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>

      <Card className="mt-3 p-4">
        <h3 className="text-md font-bold text-grey-500 mb-4">Case History</h3>
        {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
        <CaseTimeline events={events} />
      </Card>

      <Button
        className="mt-4 w-[100%]"
        onClick={() => navigate(`/edit-case/${caseDetails.case_id}`)}
      >
        Update Case
      </Button>
    </div>
  );
}

export default CaseDetails;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthHandler from "./firebase/AuthHandler";
import ProtectedRoute from "./components/ProtectedRoute";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DisputeRegistry from "./pages/DisputeRegistry";
import CaseDetails from "./pages/CaseDetails";
import EditCase from "./pages/EditCase";

import ClientList from "./pages/ClientList";
import NeutralList from "./pages/NeutralList";
import ClientProgress from "./pages/ClientCases";
import ClientPayment from "./pages/ClientPayment";
import NeutralCases from "./pages/NeutralCases";
// import RegisterDispute from "./pages/RegisterDispute";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <AuthHandler />

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/neutrals"
          element={
            <ProtectedRoute>
              <NeutralList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <ClientList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dispute-registry"
          element={
            <ProtectedRoute>
              <DisputeRegistry />
            </ProtectedRoute>
          }
        />
        <Route
          path="/case-details/:caseId"
          element={
            <ProtectedRoute>
              <CaseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-case/:caseId"
          element={
            <ProtectedRoute>
              <EditCase />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/register-dispute"
          element={
            <ProtectedRoute>
              <RegisterDispute />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/client-progress/:clientId"
          element={
            <ProtectedRoute>
              <ClientProgress />
            </ProtectedRoute>
          }
        />
        <Route
          path="/client-payment/:clientId"
          element={
            <ProtectedRoute>
              <ClientPayment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/neutral-cases/:neutralId"
          element={
            <ProtectedRoute>
              <NeutralCases />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { firestore } from "../firebase/config";
import { query, collection, where, getDocs } from "firebase/firestore";

const fetchCasesByPartyId = async (uid) => {
  try {
    // Query to find cases where party_involved.A.party_id is equal to uid
    const queryA = query(
      collection(firestore, "cases"),
      where("party_involved.A.party_id", "==", uid)
    );

    // Query to find cases where party_involved.B.party_id is equal to uid
    const queryB = query(
      collection(firestore, "cases"),
      where("party_involved.B.party_id", "==", uid)
    );

    // Fetch the documents for both queries
    const [snapshotA, snapshotB] = await Promise.all([
      getDocs(queryA),
      getDocs(queryB),
    ]);

    // Combine the results from both queries
    const casesA = snapshotA.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const casesB = snapshotB.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    // Merge and remove duplicates (if any)
    const allCases = [...casesA, ...casesB].reduce((acc, current) => {
      if (!acc.find((caseItem) => caseItem.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    if (allCases.length > 0) {
      console.log(`Fetched ${allCases.length} cases`);
    } else {
      console.log("No cases found");
    }

    return allCases;
  } catch (error) {
    console.error("Error fetching cases: ", error);
    return [];
  }
};

export default fetchCasesByPartyId;

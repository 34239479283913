import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ArrowLeft } from "lucide-react";
import Loader from "@/components/Loader";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";

import formatDate from "@/scripts/formatDate";
import fetchPaymentsByPayerId from "@/scripts/fetchPaymentsByPayerId";

function ClientPayment() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [payments, setPayments] = useState();

  useEffect(() => {
    const getData = async () => {
      const list = await fetchPaymentsByPayerId(clientId);
      setPayments(list);
    };

    getData();
  }, []);

  if (!payments) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div
        className="flex items-center mb-2 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft color="grey" />
        <p className="ml-1 text-gray-500">Back</p>
      </div>

      <h2 className="text-md text-gray-600 font-bold">Client Payments</h2>

      <Card className="p-4 mt-3">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Payment ID</TableHead>
              <TableHead className="font-bold">Case ID</TableHead>
              <TableHead className="font-bold">Purpose</TableHead>
              <TableHead className="font-bold">Amount</TableHead>
              <TableHead className="font-bold">Party Type</TableHead>
              <TableHead className="font-bold">Payment Method</TableHead>
              <TableHead className="font-bold">Status</TableHead>
              <TableHead className="font-bold">Timestamp</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {payments.map((payment, i) => (
              <TableRow key={i}>
                <TableCell className="font-medium">{i + 1}</TableCell>
                <TableCell>{payment.payment_id}</TableCell>
                <TableCell>{payment.case_id}</TableCell>
                <TableCell>{payment.purpose}</TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{payment.party_type}</TableCell>
                <TableCell>{payment.payment_method}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>
                  {new Date(payment.timestamp).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default ClientPayment;

import { firestore } from "../firebase/config";
import {
  query,
  collection,
  getDocs,
  orderBy,
  limit as firestoreLimit,
} from "firebase/firestore";

const fetchAllDisputes = async (limitValue = null) => {
  try {
    // Base query to fetch the 'cases' collection, ordered by 'start_date' in descending order
    let casesQuery = query(
      collection(firestore, "cases"),
      orderBy("start_date", "desc") // Order by 'start_date' in descending order
    );

    // Apply limit if provided
    if (limitValue) {
      casesQuery = query(casesQuery, firestoreLimit(limitValue));
    }

    // Execute the query and get the documents
    const querySnapshot = await getDocs(casesQuery);

    // If documents exist, map through them and return the list
    if (!querySnapshot.empty) {
      const list = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      console.log(`Fetched ${list.length} disputes`); // Log success message
      return list;
    } else {
      console.log("No disputes found"); // Log when no data is found
      return [];
    }
  } catch (error) {
    // Improved error handling with detailed error message
    console.error("Error fetching disputes list: ", error.message);
    return [];
  }
};

export default fetchAllDisputes;

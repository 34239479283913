import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import Loader from "@/components/Loader";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import { Input } from "@/components/ui/input"; // ShadCN input
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from "@/components/ui/select"; // ShadCN select

import formatDate from "@/scripts/formatDate";
import fetchAllDisputes from "@/scripts/fetchAllDisputes";

const categories = [
  "Account Aggregator",
  "Commercial Disputes",
  "Consumer Disputes",
  "E-Commerce Disputes",
  "Employment Disputes",
  "Family Disputes",
  "Financial Disputes",
  "Government and PSU Disputes",
  "Housing-Rental",
  "ONDC",
  "Other Civil Disputes",
  "Property Disputes",
  "Securities Markets",
];

const options = [
  "Case Registered",
  "Counselling Call",
  "Dispute Hearing",
  "Settlement",
  "Case Closed",
];

function DisputeRegistry() {
  const navigate = useNavigate();
  const [disputes, setDisputes] = useState();
  const [filteredDisputes, setFilteredDisputes] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    category: "",
    startDate: "",
    endDate: "",
  });

  const edit = (id) => navigate(`/edit-case/${id}`);
  const view = (id) => navigate(`/case-details/${id}`);

  useEffect(() => {
    const getData = async () => {
      const disputeList = await fetchAllDisputes();
      setDisputes(disputeList);
      setFilteredDisputes(disputeList); // Initially show all disputes
    };

    getData();
  }, []);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = () => {
    const filtered = disputes.filter((dispute) => {
      const matchesStatus = filters.status
        ? dispute.current_status === filters.status
        : true;
      const matchesCategory = filters.category
        ? dispute.category === filters.category
        : true;

      // Proper date comparison using Date object and formatDate function
      const matchesStartDate = filters.startDate
        ? new Date(formatDate(dispute.start_date)) >=
          new Date(filters.startDate)
        : true;
      const matchesEndDate = filters.endDate
        ? new Date(formatDate(dispute.start_date)) <= new Date(filters.endDate)
        : true;

      return (
        matchesStatus && matchesCategory && matchesStartDate && matchesEndDate
      );
    });

    setFilteredDisputes(filtered);
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      category: "",
      startDate: "",
      endDate: "",
    });
    setFilteredDisputes(disputes); // Reset the displayed data to all disputes
  };

  if (!disputes) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <h2 className="text-md text-gray-600 font-bold">Registered Disputes</h2>

      {/* Filter Section */}
      <Card className="p-4 mt-3">
        <div className="flex justify-between">
          {/* Status Dropdown */}
          <div className="w-[24%]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <Select
              value={filters.status || undefined} // Ensure selected value shows
              onValueChange={(value) =>
                setFilters({ ...filters, status: value })
              }
            >
              <SelectTrigger className="w-full" placeholder="Select Status">
                {filters.status || "Select Status"}
              </SelectTrigger>
              <SelectContent>
                {options.map((option, index) => (
                  <SelectItem key={index} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Category Dropdown */}
          <div className="w-[24%]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <Select
              value={filters.category || undefined} // Ensure selected value shows
              onValueChange={(value) =>
                setFilters({ ...filters, category: value })
              }
            >
              <SelectTrigger className="w-full" placeholder="Select Category">
                {filters.category || "Select Category"}
              </SelectTrigger>
              <SelectContent>
                {categories.map((category, index) => (
                  <SelectItem key={index} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Start Date */}
          <div className="w-[24%]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <Input
              name="startDate"
              type="date"
              value={filters.startDate}
              onChange={handleFilterChange}
              className="w-full"
            />
          </div>

          {/* End Date */}
          <div className="w-[24%]">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <Input
              name="endDate"
              type="date"
              value={filters.endDate}
              onChange={handleFilterChange}
              className="w-full"
            />
          </div>
        </div>

        {/* Apply and Reset Filters Buttons */}
        <div className="mt-3">
          <Button
            onClick={applyFilters}
            className="bg-blue-500 text-white w-[24%]"
          >
            Apply Filters
          </Button>
          <Button
            onClick={resetFilters}
            className="bg-gray-500 text-white w-[24%] ms-3"
          >
            Reset Filters
          </Button>
        </div>
      </Card>

      {/* Table Section */}
      <Card className="p-4 mt-3">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Claimant</TableHead>
              <TableHead className="font-bold">Respondent</TableHead>
              <TableHead className="font-bold">Category</TableHead>
              <TableHead className="font-bold">Status</TableHead>
              <TableHead className="font-bold">Date</TableHead>
              <TableHead className="font-bold">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredDisputes.map((dispute, i) => (
              <TableRow key={i}>
                <TableCell className="font-medium">{i + 1}</TableCell>
                <TableCell>{dispute?.party_involved?.A?.party_name}</TableCell>
                <TableCell>{dispute?.party_involved?.B?.party_name}</TableCell>
                <TableCell>{dispute?.category}</TableCell>
                <TableCell>
                  <div
                    className={`${
                      dispute?.current_status === "Case Registered"
                        ? "border-red-600 text-red-600"
                        : "border-blue-600 text-blue-600"
                    } border rounded-[20px] p-2 px-4 w-[150px] text-center`}
                  >
                    {dispute?.current_status}
                  </div>
                </TableCell>
                <TableCell className="font-medium">
                  {formatDate(dispute?.start_date)}
                </TableCell>
                <TableCell>
                  <Popover>
                    <PopoverTrigger className="bg-blue-500 p-2 px-4 text-white rounded-[20px]">
                      <MoreHorizontal />
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col">
                      <Button
                        className="m-1"
                        onClick={() => view(dispute?.case_id)}
                      >
                        View
                      </Button>
                      <Button
                        className="m-1"
                        onClick={() => edit(dispute?.case_id)}
                      >
                        Update
                      </Button>
                      <Button className="m-1">Delete</Button>
                    </PopoverContent>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default DisputeRegistry;

import { firestore } from "../firebase/config";
import { query, collection, where, orderBy, getDocs } from "firebase/firestore";

const fetchEvents = async (caseId) => {
  try {
    const eventQuery = query(
      collection(firestore, "events"),
      where("caseId", "==", caseId),
      orderBy("timestamp", "desc") // Sort by timestamp in descending order
    );

    // Fetch the documents from the events collection
    const querySnapshot = await getDocs(eventQuery);

    // Map through the documents and return the list without the separate id field
    const events = querySnapshot.docs.map((doc) => doc.data());

    return events;
  } catch (error) {
    console.error("Error fetching events: ", error);
    return [];
  }
};

export default fetchEvents;
